var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showModuloForm === false)?_c('div',[_c('MarketingModules')],1):(_vm.showModuloForm === true)?_c('div',{staticClass:"page"},[_c('Panel',{attrs:{"lineColor":"var(--primary-color)"},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('FormButtonGroup',{attrs:{"align":"end","spaced":""}},[_c('FormButtonMenu',{attrs:{"type":"secundario","options":_vm.planilhaOptions},on:{"click":function (op) { return _vm.selectOperacao(op); }}},[_vm._v(" Operação em planilha ")]),_c('FormButton',{attrs:{"text":"Nova politica de desconto","type":"primario"},on:{"click":_vm.onNew}})],1)]},proxy:true}])},[_c('DataTable',{ref:"datatable",attrs:{"headers":_vm.headers,"endpoint":_vm.endpoint,"sortBy":"name","sortDesc":true,"search":""},on:{"click":_vm.onClick},scopedSlots:_vm._u([{key:"quando",fn:function(ref){
var col = ref.col;
return [_vm._v(" "+_vm._s(_vm.renderVigencia(col))+" ")]}},{key:"entao",fn:function(ref){
var col = ref.col;
return [_vm._v(" "+_vm._s(_vm.renderEfeitos(col))+" ")]}},{key:"todos_clientes",fn:function(ref){
var col = ref.col;
return [(col || col === 'true')?_c('span',[_vm._v("Todos")]):_c('span',[_vm._v("Específicos")])]}},{key:"metadados",fn:function(ref){
var row = ref.row;
return [_c('Chip',{attrs:{"color":_vm.renderFaixaDecorativaCor(row),"text":_vm.renderFaixaDecorativa(row),"small":true}})]}},{key:"itemnum",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(_vm.renderPrecoOriginal(row))+" ")])]}},{key:"idpolitica",fn:function(ref){
var row = ref.row;
return [_c('FormButtonMenu',{attrs:{"icon":"","options":_vm.tableOptions},on:{"click":function (op) { return _vm.selectOption(row, op); }}},[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("fas fa-ellipsis-v")])],1)]}}])})],1),_c('transition',{attrs:{"name":"slide-popup","mode":"out-in"}},[(_vm.modalEdicaoEmPlanilha.open)?_c('EdicaoEmPlanilha',{attrs:{"modelo":_vm.modalEdicaoEmPlanilha.modelo,"filtro":_vm.modalEdicaoEmPlanilha.filtro},on:{"close":function($event){_vm.modalEdicaoEmPlanilha.open = false}}}):_vm._e()],1),_c('transition',{attrs:{"name":"slide-popup","mode":"out-in"}},[_c('router-view',{staticClass:"view",on:{"close":_vm.onClose}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }