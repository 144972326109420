<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <template v-slot:options>
        <FormButtonGroup align="end" spaced>
          <FormButtonMenu
            type="secundario"
            :options="planilhaOptions"
            @click="op => selectOperacao(op)"
          >
            Operação em planilha
          </FormButtonMenu>
          <FormButton
            text="Nova politica de desconto"
            type="primario"
            @click="onNew"
          />
        </FormButtonGroup>
      </template>
      <DataTable
        :headers="headers"
        :endpoint="endpoint"
        sortBy="name"
        :sortDesc="true"
        @click="onClick"
        search
        ref="datatable"
      >
        <template v-slot:quando="{ col }">
          {{ renderVigencia(col) }}
        </template>
        <template v-slot:entao="{ col }">
          {{ renderEfeitos(col) }}
        </template>
        <template v-slot:todos_clientes="{ col }">
          <span v-if="col || col === 'true'">Todos</span>
          <span v-else>Específicos</span>
        </template>
        <template v-slot:metadados="{ row }">
          <Chip
            :color="renderFaixaDecorativaCor(row)"
            :text="renderFaixaDecorativa(row)"
            :small="true"
          />
        </template>
        <template v-slot:itemnum="{ row }">
          <div>
            {{ renderPrecoOriginal(row) }}
          </div>
        </template>
        <template v-slot:idpolitica="{ row }">
          <FormButtonMenu
            icon
            :options="tableOptions"
            @click="op => selectOption(row, op)"
          >
            <v-icon size="15">fas fa-ellipsis-v</v-icon>
          </FormButtonMenu>
        </template>
      </DataTable>
    </Panel>
    <transition name="slide-popup" mode="out-in">
      <EdicaoEmPlanilha
        v-if="modalEdicaoEmPlanilha.open"
        :modelo="modalEdicaoEmPlanilha.modelo"
        :filtro="modalEdicaoEmPlanilha.filtro"
        @close="modalEdicaoEmPlanilha.open = false"
      />
    </transition>
    <transition name="slide-popup" mode="out-in">
      <router-view class="view" @close="onClose"></router-view>
    </transition>
  </div>
</template>

<script>
import Chip from '@/components/Chip'
import Panel from '@/components/Panel'
import DataTable from '@/components/DataTable'
import FormButton from '@/components/form/FormButton'
import FormButtonGroup from '@/components/form/FormButtonGroup'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import EdicaoEmPlanilha from '@/components/EdicaoEmPlanilha'
import { POLITICA_DESCONTO_REMOVE } from '@/store/actions/politicaDesconto'
import { mapGetters } from 'vuex'
import * as _ from 'lodash'
import moment from 'moment'
import MarketingModules from '@/components/MarketingModules'
import { ModulesEnum } from '@/store/actions/modulesEnum'

export default {
  name: 'PoliticaDescontoTable',
  components: {
    Chip,
    DataTable,
    Panel,
    FormButton,
    FormButtonGroup,
    FormButtonMenu,
    EdicaoEmPlanilha,
    MarketingModules
  },
  data: () => ({
    headers: [
      {
        text: 'Nome',
        value: 'descricao'
      },
      {
        text: 'Vigência',
        value: 'quando',
        align: 'center'
      },
      {
        text: 'Efeitos',
        value: 'entao',
        align: 'center'
      },
      {
        text: 'Código interno',
        value: 'id_politica_erp',
        align: 'center'
      },
      {
        text: 'Clientes',
        value: 'todos_clientes',
        align: 'center'
      },
      {
        text: 'Faixa decorativa',
        value: 'metadados',
        align: 'center'
      },
      {
        text: 'Preço original',
        value: 'itemnum',
        align: 'center'
      },
      {
        text: 'Opções',
        value: 'idpolitica',
        clickable: false,
        align: 'center'
      }
    ],
    tableOptions: [{ text: 'Deletar', value: 'delete' }],
    planilhaOptions: [
      { text: 'Editar politicas', value: 'planilha_politica' },
      {
        text: 'Editar vinculo cliente',
        value: 'planilha_vinculo_cliente_politica'
      },
      {
        text: 'Editar vinculo produto',
        value: 'planilha_vinculo_produto_politica'
      }
    ],
    modalEdicaoEmPlanilha: {
      open: false,
      modelo: 'planilha_politica',
      filtro: 'código interno'
    },
    modulosForm: [
      ModulesEnum.VENDAMAIS_WEB,
      ModulesEnum.VENDAMAIS_APP,
      ModulesEnum.COMMERCE_APP,
      ModulesEnum.WEB_COMMERCE
    ]
  }),
  computed: {
    ...mapGetters(['getFornecedorId', 'getEmpresaId', 'moduloFornecedorAtivo']),
    endpoint() {
      return `/api/v4/discount-policies/${this.getFornecedorId}/${this.getEmpresaId}?fp=todos`
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  created() {},
  methods: {
    onClose() {
      this.$refs.datatable.reload()
    },
    onClick(item) {
      this.$router.push(`politicas-desconto/${item.idpolitica}`)
    },
    selectOperacao(option) {
      const edicaoPlanilha = option.value.startsWith('planilha')
      if (edicaoPlanilha) {
        this.modalEdicaoEmPlanilha = {
          open: true,
          modelo: option.value,
          filtro: 'código interno'
        }
        return
      }
    },
    onNew() {
      this.$router.push('politicas-desconto/novo')
    },
    selectOption(item, option) {
      switch (option.value) {
        case 'delete':
          return this.onDelete(item)
      }
    },
    onDelete(item) {
      this.$swal
        .fire({
          title: `Remover politica de desconto? `,
          text: 'Essa ação é irreversível',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
        })
        .then(result => {
          if (result.value) this.delete(item)
          else return
        })
    },
    delete(item) {
      this.$store
        .dispatch(POLITICA_DESCONTO_REMOVE, item.idpolitica)
        .then(() => {
          this.$vueOnToast.pop(
            'success',
            'Politica de desconto removida com sucesso'
          )
          this.$refs.datatable.reload()
        })
    },
    renderVigencia(value) {
      const vigencia_inicial = value.find(v => v.nome === 'vigencia_inicial')
      const vigencia_final = value.find(v => v.nome === 'vigencia_final')
      const dInicial = _.get(vigencia_inicial, 'valor', '')
      const dFinal = _.get(vigencia_final, 'valor', '')
      return (
        moment(dInicial).format('DD/MM/YYYY') +
        ' - ' +
        moment(dFinal).format('DD/MM/YYYY')
      )
    },
    renderEfeitos(value) {
      const indicePreco = value.find(v => v.key === 'indice_preco')
      let iPreco = _.get(indicePreco, 'value', '')
      if (iPreco) {
        return (
          'Fator de:  ' +
          parseFloat(iPreco).toLocaleString('pt-BR', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 4
          })
        )
      }
      const precoFixo = value.find(v => v.key === 'preco_fixo')
      iPreco = _.get(precoFixo, 'value', '')
      if (iPreco) {
        return (
          'Preço fixo: R$ ' +
          parseFloat(iPreco).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        )
      }
      const descontoProgressivo = value.find(
        v => v.key === 'desconto_progressivo'
      )
      if (descontoProgressivo) {
        return 'Desconto progressivo'
      }
      return ''
    },
    renderFaixaDecorativaCor(row) {
      const corDestaque = _.get(row, 'entao', []).find(
        v => v.key === 'cor_destaque'
      )
      return corDestaque ? corDestaque.value : ''
    },
    renderFaixaDecorativa(row) {
      const faixaDecorativa = _.get(row, 'entao', []).find(
        v => v.key === 'anotacao_destaque'
      )
      return faixaDecorativa ? faixaDecorativa.value : ''
    },
    renderPrecoOriginal(value) {
      const mostrarPrecoOriginal = _.get(value, 'entao', []).find(
        v => v.key === 'mostrar_preco_original'
      )
      return mostrarPrecoOriginal && !!mostrarPrecoOriginal.value
        ? 'Exibir'
        : 'Esconder'
    }
  }
}
</script>

<style scoped>
.olive {
  background-color: #b5cc18 !important;
  border-color: #b5cc18 !important;
}

.violet {
  background-color: #6435c9 !important;
  border-color: #6435c9 !important;
}

.v-chip {
  cursor: pointer;
}
</style>
